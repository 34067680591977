var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"userWrapper",staticClass:"user-wrapper"},[_c('div',[_c('div',{staticClass:"user-header"},[_c('div',{staticClass:"user-header__photo async async--img",style:({ backgroundImage: `url(${_vm.user.photo})` })}),_c('h1',{staticClass:"user-header__title async async--text"},[_vm._v(_vm._s(_vm.user.name))]),_c('p',{staticClass:"user-header__email async async--text"},[_vm._v(_vm._s(_vm.user.email))])]),_c('div',{staticClass:"user-xp"},[_c('div',{key:_vm.user.lvl,staticClass:"user-xp-wrapper"},[_c('i',{staticClass:"user-xp__level fas fa-star",style:({ color: _vm.hashColor(_vm.user.lvl) })},[_c('span',{staticClass:"user-xp__text"},[_vm._v(" "+_vm._s(_vm.user.lvl != null ? _vm.user.lvl : "?")+" ")])]),_c('div',{staticClass:"user-xp__bar"},[_c('span',{staticClass:"user-xp__text",style:(_vm.xpTextStyle(_vm.user.lvl))},[_vm._v(" "+_vm._s(_vm.user.lvlXp != null ? _vm.user.lvlXp : "-")+" / "+_vm._s(_vm.user.reqLvlXp != null ? _vm.user.reqLvlXp : "-")+" "+_vm._s(_vm.$t("user.experience-points"))+" ")]),_c('div',{staticClass:"user-xp__bar--fill",style:({
              background: _vm.hashColor(_vm.user.lvl),
              maxWidth: `${(_vm.user.lvlXp / _vm.user.reqLvlXp) * 100}%`
            })})]),_c('div',{staticClass:"user-xp__level"},[_c('span',{staticClass:"user-xp__text"},[_vm._v(" "+_vm._s(_vm.user.maxStreak)+" ")]),_c('img',{attrs:{"height":"100%","src":require('@/assets/fire.svg')}})])])])]),_c('div',{ref:"tabs",staticClass:"tabs"},[_c('div',{staticClass:"tab-options"},_vm._l((_vm.tabs),function(tab){return _c('div',{key:tab.id,staticClass:"tabs__tab-option",class:{ active: _vm.isActiveTab(tab.id) },on:{"click":function($event){return _vm.showTab(tab.id)}}},[_c('i',{class:tab.icon})])}),0),_c('div',{ref:"activeTabMarker",staticClass:"active-tab-marker",style:({
        width: `calc(${100 / _vm.tabs.length}%`
      })})]),_c('div',{ref:"tabContainer",staticClass:"tab-container"},_vm._l((_vm.tabs),function(tab){return _c('div',{key:tab.id,staticClass:"tab-content",class:{ active: _vm.isActiveTab(tab.id) },style:({
        transform: `translateX(${tab.id * 100}%`,
        minHeight: `${_vm.tabContentHeight}px`
      })},[_c(tab.component,{tag:"component",staticClass:"tab-component"})],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }