var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('GmapMap',{ref:"mapRef",class:_vm.panel == _vm.QR_SPOT_PANEL.SHOW_DETAILS ? 'collapsed-map' : 'expanded-map',attrs:{"center":_vm.mapCoords,"zoom":_vm.mapZoom,"options":{
    mapId: '153063bbe11287f1',
    gestureHandling: 'greedy',
    zoomControl: true,
    zoomControlOptions: { position: 3 },
    scaleControl: false,
    rotateControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    clickableIcons: false,
    draggable: _vm.panel !== _vm.QR_SPOT_PANEL.SHOW_DETAILS
  }},on:{"click":_vm.deselect,"dragend":_vm.handleDrag,"zoom_changed":_vm.handleZoom,"heading_changed":heading => (_vm.mapHeading = heading),"tilt_changed":tilt => (_vm.mapTilt = tilt)}},[_c('GmapInfoWindow',{attrs:{"options":_vm.infoWindow.options,"position":_vm.infoWindow.coords,"opened":_vm.infoWindow.open},on:{"closeclick":function($event){_vm.infoWindow.open = false}}}),(_vm.userCoords)?_c('GmapMarker',{attrs:{"position":_vm.userCoords,"z-index":100,"icon":{
      url: require('@/assets/position-marker.svg'),
      anchor: { x: 12, y: 12 }
    }},on:{"click":_vm.centerMapToUser}}):_c('GmapMarker',{attrs:{"position":{ lat: 0, lng: 0 },"z-index":100,"icon":{
      url: require('@/assets/spinner.svg'),
      anchor: { x: 50, y: 50 },
      scaledSize: { width: 100, height: 100 }
    },"label":{
      text: 'Please enable location access',
      fontSize: '1rem',
      color: '#242424'
    }},on:{"click":_vm.centerMapToUser}}),(_vm.userCoords)?_c('GmapCircle',{attrs:{"center":_vm.userCoords,"radius":20,"options":{
      clickable: false,
      fillColor: '#0042FF',
      fillOpacity: '0.15',
      strokeColor: '#FFFFFF',
      strokeOpacity: '0.5',
      strokeWeight: '2'
    }}}):_vm._e(),_vm._l((_vm.markers),function(marker,index){return _c('GmapMarker',{key:index,attrs:{"position":{ lat: Number(marker.lat), lng: Number(marker.lng) },"clickable":_vm.panel !== _vm.QR_SPOT_PANEL.SHOW_DETAILS,"icon":_vm.getIcon(marker)},on:{"click":() => _vm.select(marker)}})}),_vm._l((_vm.markers),function(marker,index){return _c('GmapCircle',{key:`${index}-c`,attrs:{"center":{ lat: Number(marker.lat), lng: Number(marker.lng) },"radius":15,"options":{
      clickable: false,
      fillColor: '#54341f',
      fillOpacity: '0.2',
      strokeColor: '#54341f',
      strokeOpacity: '0.5',
      strokeWeight: '2'
    }}})}),_vm._l((_vm.recent(_vm.markers)),function(marker,index){return _c('GmapMarker',{key:`${index}-o`,attrs:{"position":{ lat: Number(marker.lat), lng: Number(marker.lng) },"clickable":false,"icon":{
      url: require('@/assets/puff.svg'),
      anchor: { x: 32, y: 32 },
      scaledSize: { width: 64, height: 64 }
    }}})}),_c('div',{staticClass:"control-button",attrs:{"id":"streak-button"},on:{"click":_vm.explainStreak}},[_c('div',{staticClass:"control-button__streak",class:{ 'no-streak': !_vm.showStreak }},[(_vm.showStreak)?_c('Flame'):_vm._e(),_c('p',{staticClass:"control-button__streak__count"},[_vm._v(" "+_vm._s(_vm.user.streak)+" ")])],1)]),_c('div',{staticClass:"control-button",attrs:{"id":"position-button"},on:{"click":_vm.centerMapToUser}},[_c('img',{staticClass:"control-button__icon",attrs:{"alt":"My Location","src":require('@/assets/position-button.svg')}})]),_c('div',{staticClass:"control-button",attrs:{"id":"compass-button"},on:{"click":_vm.resetHeading}},[_c('div',{staticClass:"control-button__inner",style:(`transform: rotateX(-${_vm.mapTilt}deg)`)},[_c('img',{staticClass:"control-button__icon",style:(`transform: rotate(-${_vm.mapHeading}deg)`),attrs:{"alt":"Compass","src":require('@/assets/compass.svg')}})])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }